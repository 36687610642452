const color_specification = {
  purple : 'rgb(128, 0, 190);',
  white: 'rgb(255,255,255)',
  primary: 'blue',
  gray: '#F7F7F7',
  black: 'rgb(0,0,0)',
  burney_purple: '#8000be'
}
const font_detaile = {
  gotham: 'Gotham, Arial, Helvetica, sans-serif',
  gotham_bold: 'Gotham-Bold, Arial, Helvetica, sans-serif',
  gotham_book: 'Gotham-Book, Arial, Helvetica, sans-serif'
}

export const color = {
  purple: `${color_specification.purple}`,
  primary: `${color_specification.primary}`,
  gray: `${color_specification.gray}`,
  black: `${color_specification.black}`,
  white: `${color_specification.white}`,
  burney_purple: `${color_specification.burney_purple}`
};

export const font = {
  gotham: `${font_detaile.gotham}`,
  gotham_bold: `${font_detaile.gotham_bold}`,
  gotham_book: `${font_detaile.gotham_book}`
}