import React from 'react'
import Layout from "@retina-packages/retina-theme-bootstrap/packages/components/layout"
import { graphql } from 'gatsby'
import { PageWrapper, HcpMobileBanner, HcpInheritedRetinalDisease, HcpPage } from '@retina-packages/retina-theme-bootstrap/packages/css/CommonStyle'
import { DiagnosingInheritedRetinal } from './styles'
import MetaTagContainer from '@retina-packages/retina-theme-bootstrap/packages/containers/MetaTagContainer'
import { Props, PageQueryResult } from '@retina-packages/retina-theme-bootstrap/packages/types/CommonTypes'
import { exitPopDataType } from '@retina-packages/retina-theme-bootstrap/packages/types/ExitPopUpType'
import { reusableDataBuilders } from '@retina-packages/retina-theme-bootstrap/packages/helpers'
import retinaConfig from '../../../utils/retinaConfigs'
import { HTMLRenderer } from '@retina-packages/retina-theme-bootstrap'
import { MobileBanner } from '@retina-packages/retina-theme-bootstrap/packages/components/styles/styles'
import CircularInfographics from '@retina-packages/retina-theme-bootstrap/packages/components/InfographicDonut/CircularInfographics'
import { HcpDIRD } from './constants'
import { deriveSiteLogo, deriveSiteMenus, footRefHTMLBuilder, fullHTMLDataBuilder, infographicDataBuilder } from '../../../utils'


/** Renders inherited-retinal-diseases-page template
 *
 * @param props props
 * @returns
 */
const GeneticTestingBenefitTemplate = (props: Props) => {
  const pgProp: PageQueryResult = props.data
  const blocks = pgProp.nodePage.relationships.field_paragraphs_gcso
  const pgContextsIRDH = props.pageContext
  const htmlStaticFilesIRDH = {
    nonSVGImages: pgContextsIRDH.nonSVGImages,
    svgMediaImages: pgContextsIRDH.svgMediaImages,
    allMediaDocument: pgContextsIRDH.documents
  }


  const { mainMenu, footerMenu } = deriveSiteMenus({ pgProp, siteMainMenu: HcpDIRD.hcpDIRDMainMenu, siteFooterMenu: HcpDIRD.hcpDIRDFooterMenu, addHCPPrefix: retinaConfig.addHcpPrefix })
  const audience = pgProp.nodePage.relationships.field_general_tags_gcso[0].name
  const bannerTitle = ""
  const carouselTitle = ""
  const footerTitle = HcpDIRD.hcpFooter
  const exitPopupTitle = HcpDIRD.hcpExitPopup
  const hcpSwitcherTitle = HcpDIRD.hcpHCPSwitcher
  const topNavTitle = HcpDIRD.hcpTopNav

  const {
    footerText,
    exitData,
    hcpSwitcher,
    topNavs
  }: {
    footerText: string
    exitData: exitPopDataType
    hcpSwitcher: exitPopDataType
    topNavs: string
  } = reusableDataBuilders({ blocks, bannerTitle, carouselTitle, audience, footerTitle, exitPopupTitle, hcpSwitcherTitle, topNavTitle })

  const siteLogos = deriveSiteLogo({ blocks, title: HcpDIRD.hcpSiteLogo })
  const bannerHTML = fullHTMLDataBuilder({ blocks, title: HcpDIRD.hcpDIRDBanner })
  const hcpDIRDReference = footRefHTMLBuilder({ blocks, title: HcpDIRD.hcpDIRDFootRef })
  const overlapHTML = fullHTMLDataBuilder({ blocks, title: HcpDIRD.overLapDIRD })
  const goingBeyondHTML = fullHTMLDataBuilder({ blocks, title: HcpDIRD.goingBeyondDIRD })
  const familyHistoryHTML = fullHTMLDataBuilder({ blocks, title: HcpDIRD.familyHistoryDIRD })
  const { per, text1, text2 } = infographicDataBuilder({ blocks, title: HcpDIRD.infographicDIRD })
  const caseButtonHTML = fullHTMLDataBuilder({ blocks, title: HcpDIRD.caseButtonDIRD })
  const earlyTestHTML = fullHTMLDataBuilder({ blocks, title: HcpDIRD.earlyTestDIRD })
  const donutInfoGraData = { 'per': `${per}`, 'circular_text': text1, 'right_text': text2 }

  return (
    <div className='hcppage'>
      <HcpPage className="hcp-page">
        <HcpInheritedRetinalDisease>
          <HcpMobileBanner className="hcpmobilewrapper hcp-diagnosis-inherited-retinal-footer-wrapper">
            {props.pageContext !== null && props.pageContext.metaInfo !== null && (
              <MetaTagContainer metaData={props.pageContext.metaInfo} />
            )}
            <Layout
              title={"siteTitle"}
              location={props.location}
              data={mainMenu}
              mdata={footerMenu}
              footerData={footerText}
              audience={audience}
              footref={hcpDIRDReference}
              exitPopData={exitData}
              hcplinks={hcpSwitcher}
              topNavigation={topNavs}
              staticMediaFiles={htmlStaticFilesIRDH}
              siteLogos={siteLogos}
              showRefContent={retinaConfig.showRefContent}
              hideRefContent={retinaConfig.hideRefContent}
              backToTopContent={retinaConfig.backToTopContent}
              gtmBackToTopLabel={HcpDIRD.backToTopGTM}
              modalCloseButtonLabel={retinaConfig.modalCloseButtonLabel}
              modalCloseButtonGTMLabel={retinaConfig.modalCloseButtonGTMLabel}
              toggleExpandAllUnderline={false}
              hcpValidate={retinaConfig.hcpValidate}
              preIndexUrl={retinaConfig.preIndexUrl}
              hcpHomeUrl={retinaConfig.hcpHomeUrl}
              hcpPrefix={retinaConfig.hcpPrefix}
              siteHomeUrl={retinaConfig.siteHomeUrl}
              footerClassName={HcpDIRD.footerClassName}
              cpraFooter={true}
              cpraFooterEnv={process.env.CPRA_FOOTER_ENV}
              cpraOT={true}
              cpraOTEnv={process.env.OT_ENV}
              cpraOTuuid={process.env.OT_UUID}
              {... { ...retinaConfig.layoutAttrs.hcpAttrs, ...retinaConfig.layoutAttrs.commonAttrs }}
            >
              <PageWrapper className='pagewrapper diagnosing-inherited-retinal-diseases'>
                <DiagnosingInheritedRetinal className="hcpinheritedretinal">
                  <MobileBanner className="mobile-banner clinical-trials-banner">
                    <HTMLRenderer data={htmlStaticFilesIRDH} html={bannerHTML} tagName='section' className='internal-banner o-column--full' simpleImage={true} />
                  </MobileBanner>
                  <div className='main-section o-top-space'>
                    <HTMLRenderer data={htmlStaticFilesIRDH} html={overlapHTML} />
                    <HTMLRenderer data={htmlStaticFilesIRDH} html={goingBeyondHTML} />
                    <div className="c-family--story inherited-retinal-disease-c-family mt-0 mb-0">
                      <div className="o-container">
                        <div className="c-family--story__inner o-border-gradient o-bottom-space infogrphic-color">
                          <HTMLRenderer data={htmlStaticFilesIRDH} html={familyHistoryHTML} />
                          <CircularInfographics data={donutInfoGraData} audience={audience} />
                          <HTMLRenderer data={htmlStaticFilesIRDH} html={caseButtonHTML} className='case-study-btn' tagName='div' />
                        </div>
                      </div>
                    </div>
                    <HTMLRenderer data={htmlStaticFilesIRDH} html={earlyTestHTML} />
                  </div>
                </DiagnosingInheritedRetinal>
              </PageWrapper>
            </Layout>
          </HcpMobileBanner>
        </HcpInheritedRetinalDisease>
      </HcpPage>
    </div>
  )
}

export default GeneticTestingBenefitTemplate

export const pageQuery = graphql`
  query ($id: Int!) {
    nodePage(drupal_internal__nid: {eq: $id}) {
      drupal_internal__nid
      relationships {
        ... DiagnosingIRDPageQuery
      }
    }
  }
`