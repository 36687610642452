export const HcpDIRD: any = {
  hcpDIRDMainMenu: "Main Navigational Menu HCP GCSO - Retina Global",
  hcpDIRDFooterMenu: "Footer Navigation Menu HCP GCSO - Retina Global",
  hcpBanner: "Banner - DIAGNOSING INHERITED RETINAL DISEASES - Global",
  hcpFooter: "Retina Global Footer HCP - US - Global",
  hcpExitPopup: "Retina Canada Exit Popup New - Global",
  hcpHCPSwitcher: "HCP Switcher Modal Pop Up - Global",
  hcpTopNav: "Top Navigational Menu HCP US - Global",
  hcpSiteLogo: "Site logos - Global",
  hcpDIRDBanner: "Banner - HTML - IRD - HCP - US Global",
  hcpDIRDFootRef: "Diagnosing Inherited Retinal Diseases HCP - References - Global",
  hcpDIRDVideoCarousel: "DNA Video and Carousel",
  overLapDIRD: "Diagnosing Inherited Retinal Diseases - overlapping presentations New - Global",
  goingBeyondDIRD: "Diagnosing Inherited Retinal Diseases - Going beyond the clinical - Global",
  familyHistoryDIRD: "Diagnosing Inherited Retinal Diseases - Family history - Global",
  infographicDIRD: "Diagnosing Inherited Retinal Diseases - Infographic - Global",
  caseButtonDIRD: "Diagnosing Inherited Retinal Diseases - Read case study button - Global",
  earlyTestDIRD: "Diagnosing Inherited Retinal Diseases - Early testing matters - Global",
  backToTopGTM: "Back to top - Inherited Retinal Diseases",
  footerClassName: "investigating-irds-footer"
}
